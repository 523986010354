
/* actions 类型type(标识信息) */
export const SIDERKEY = 'SIDERKEY';
export const KNOWLEDGESHAREKEY = 'KNOWLEDGESHAREKEY';
export const SEARCH_TOPIC = "SEARCH_TOPIC";
export const SEARCH_USERLIST = 'SEARCH_USERLIST';
export const SEARCH_LOGINLIST = 'SEARCH_LOGINLIST';
export const SEARCH_RANK = "SEARCH_RANK";
export const SEARCH_SUBMIT = "SEARCH_SUBMIT";
export const CONTESTS = 'CONTESTS';
export const SEARCH_INPUT = 'SEARCH_INPUT';
export const MONACODE = 'MONACODE';
export const ONLINEHELP = 'ONLINEHELP';
export const SEARCH_MYINPUT = 'SEARCH_MYINPUT';
export const FAVORITES = 'FAVORITES';
export const SEARCH_SUBMITDETAIL = 'SEARCH_SUBMITDETAIL';
export const CODEWINDOW = 'CODEWINDOW';

export const SEARCH_STUDENT = 'SEARCH_STUDENT';

export const MYCONTESTS = 'MYCONTESTS';

export const CONTESTRANK = 'CONTESTRANK';

export const CONTESTCODE = 'CONTESTCODE';

export const MESSAGE = 'MESSAGE';

export const TOPICS = 'TOPICS';
export const SUMMARYTODAY = 'SUMMARYTODAY';
export const RESET = 'RESET';
export const CHOICETOPIC = 'CHOICETOPIC';
export const EVALUATION = 'EVALUATION';
export const EVALUATIONCENTER = 'EVALUATIONCENTER';
export const EVALUATIONMGMT = 'EVALUATIONMGMT';
export const EVALUATIONMGMTLIST = 'EVALUATIONMGMTLIST';
export const SCRATCHSUBMITLIST = 'SCRATCHSUBMITLIST';
export const SCRATCHSUBMIT = 'SCRATCHSUBMIT';

export const WORKPLACE = 'WORKPLACE';

export const JOBMGMT = 'JOBMGMT';

export const QUESTIONBANK = 'QUESTIONBANK';

export const STUDENTMGMT = 'STUDENTMGMT';

export const CLASSSIGNIN = 'CLASSSIGNIN';

export const CHECKINHISTORY = 'CHECKINHISTORY';

export const CLASSSHOT = 'CLASSSHOT';
export const PORTRAIT = 'PORTRAIT';
export const PLATFORM = 'PLATFORM';
export const SNAPSHOT = 'SNAPSHOT';
export const SNAPSHOTDETAIL = 'SNAPSHOTDETAIL';
export const SCHOOLMGMT = 'SCHOOLMGMT';
export const TOPICMGMT = 'TOPICMGMT';

export const EQUITYMGMT = 'EQUITYMGMT';

export const CLASSPERFORMANCE = 'CLASSPERFORMANCE';

export const MYCOURSE = 'MYCOURSE';

export const MYCOURSEMGMT = 'MYCOURSEMGMT';

export const EXPORTTOPICS = 'EXPORTTOPICS';

export const TOPICADD = 'TOPICADD';

export const CHECKTESTCODE = 'CHECKTESTCODE'

export const TOPICEDITFLOW = 'TOPICEDITFLOW'

export const FLOWCHART = 'FLOWCHART'

export const TOPICDETAILFLOW = 'TOPICDETAILFLOW'

export const SUBMITTOPICDETAILFLOW = 'SUBMITTOPICDETAILFLOW'

export const COMPLETIONTOPICLIST = 'COMPLETIONTOPICLIST'

export const BLOCKLYTOPICLIST = 'BLOCKLYTOPICLIST'

export const BROADCAST = 'BROADCAST'

export const TEACHERBROADCAST = 'TEACHERBROADCAST'