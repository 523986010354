import env from './../config';
class API {
    static URL = env.WEB_URL;
    static SOCKET_URL = env.SOCKET_URL;

    static login(login) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'jyedu-backend/login?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(login),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static getCurrentServerTime() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getCurrentServerTime?refresh_token=' + refresh_token, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                return new Date().getTime();
            }
        });
    }

    static getCodeTopicList(topicType, options) {
        if (topicType == '0') {
            return API.getTopicList(options)
        } else if (topicType == '1') {
            return API.getChoiceTopicList(options)
        } else {
            return API.getCompletionTopicList(options)
        }
    }

    static retrySubmitEvaluation(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/retrySubmitEvaluation?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('提交重新答题失败');
                error.response = res
                throw error
            }
        });
    }

    static getMyContestList(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getMyContestList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(contest),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static getRedisMyContestList(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getRedisMyContestList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(contest),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static getStudentContestList(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getStudentContestList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(contest),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static getRankList(className, dateRange, page, pageSize, searchInput) {
        let values = {};
        values.className = className;
        values.dateRange = dateRange;
        values.page = page
        values.pageSize = pageSize
        values.userId = searchInput
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getRankList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getRedisRankList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getRedisRankList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getPersonalRank() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getPersonalRank?refresh_token=' + refresh_token, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRealList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getRealList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getClassList(userId, userRole, searchData) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getClassList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ userId: userId, userRole: userRole, searchData: searchData }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getContestClassList(cid) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getContestClassList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: cid }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getAllSubmitHistory(solutionId, topicName, userId, submitDate, classId, page, pageSize, result, sourcePage) {
        let params = {};
        params.solutionId = solutionId;
        params.topicName = topicName;
        params.userId = userId;
        params.submitDate = submitDate;
        params.classId = classId;
        params.page = page;
        params.pageSize = pageSize;
        params.result = result
        params.sourcePage = sourcePage
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getAllSubmitHistory?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(params),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getTodaySummary(classSearch, startDatetime, endDatetime) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getTodaySummary?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ classId: classSearch, startDatetime: startDatetime, endDatetime: endDatetime }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static exportEvaluation(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/exportEvaluation?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.blob();
            } else {
                throw new Error(status);
            }
        });
    }

    static getCourseList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getCourseList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addCourse(course) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/addCourse?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(course),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static editCourse(course) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/editCourse?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(course),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteCourse(courseId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/deleteCourse?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ courseId: courseId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static sortKnowledgeList(articles) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/sortKnowledgeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ articles: articles }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getKnowledgeList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getKnowledgeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getKnowledgeDetail(kId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getKnowledgeDetail?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ kId: kId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRedisContestTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getRedisContestTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLessonPlanTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getLessonPlanTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getPlanTopic(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getPlanTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLatestContestTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getLatestContestTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestEditTopicList(contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestEditTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ contestId: contestId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicDetail(problemId, contestId, plan_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicDetail?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ topicId: problemId, contestId: contestId, plan_id: plan_id }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getQuestionSubmissionAndPassCount(problemId, contestId, plan_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getQuestionSubmissionAndPassCount?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ topicId: problemId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicDimension(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicDimension?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRedisTopicDetail(problemId, contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getRedisTopicDetail?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ topicId: problemId, contestId: contestId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getPersonalSubmitHistory(problemId, userId, page, pageSize) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getPersonalSubmitHistory?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ problem_id: problemId, user_id: userId, page: page, pageSize: pageSize }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRuntimeInfo(result) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getRuntimeInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(result),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static setNewPassword(resetPassword) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'jyedu-backend/user/setNewpassword?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(resetPassword),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(
            (res) => {
                return res.json();
            }
        ).catch(err => {
            return err;
        });
    }

    static updateProfile(update) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateProfile?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(update),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getContestRank(plan_id, classId, contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestRank?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ plan_id: plan_id, classId: classId, contestId: contestId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getSolutionList(solutionList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getSolutionList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(solutionList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static addDiscussCode(discussCode) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/addDiscussCode?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(discussCode),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static replyDiscussCode(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/replyDiscussCode?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static submitSolution(solution) {
        const token = sessionStorage.getItem('token');
        let data = JSON.stringify({
            userId: solution.userId,
            language: solution.language,
            source: solution.source,
            problemId: solution.problemId,
            contestId: solution.contestId,
            num: solution.num
        });
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/submitSolution?refresh_token=' + refresh_token, {
            method: 'POST',
            body: data,
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static submitCustomSolution(solution) {
        const token = sessionStorage.getItem('token');
        let data = JSON.stringify({
            language: solution.language,
            source: solution.source,
            input_text: solution.input_text
        });
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/submitCustomSolution?refresh_token=' + refresh_token, {
            method: 'POST',
            body: data,
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getCustomRunResult(solutionId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getCustomRunResult?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ solutionId: solutionId }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }
    static getDiscussCodeList(classId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getDiscussCodeList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ classId: classId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteDiscussCode(id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/deleteDiscussCode?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ id: id }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUnReplyDiscuss() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getUnReplyDiscuss?refresh_token=' + refresh_token, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getSchoolList(school) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getSchoolList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(school),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static addSchool(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addSchool?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static updateSchool(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateSchool?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteSchool(school_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteSchool?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ school_id }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addClass(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addClass?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static updateClass(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateClass?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static deleteClass(class_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteClass?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ class_id }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTeacherList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getTeacherList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static getUserList(searchContent) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getUserList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(searchContent),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRoleList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getRoleList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            },
        );
        return fetch(request)
            .then(res => {
                const status = res.status;
                if (status >= 200 && status < 300) {
                    return res.json();
                } else {
                    throw new Error(status);
                }
            });
    }

    static addUser(addUser) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addUser?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(addUser),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request)
    }

    static setUserByAdmin(values) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/resetUserByAdmin?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(values),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request)
    }

    static getPortraitList(portrait) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPortraitList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(portrait),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addPortrait(portrait) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addPortrait?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: portrait,
                headers: new Headers({ Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getPortraitDetail(pId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPortraitDetail?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ pId: pId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editPortrait(portrait) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/editPortrait?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: portrait,
                headers: new Headers({ Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deletePortrait(pId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deletePortrait?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ pId: pId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicList(pagination) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicRelatedContestList(problem_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getTopicRelatedContestList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ problem_id: problem_id }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicSet(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicSet?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createTopicSet(topicSet) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createTopicSet?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(topicSet),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static updateTopicSet(topicSet) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/updateTopicSet?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(topicSet),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static deleteTopicSet(set_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/deleteTopicSet?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ set_id }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getMyCreatedContestList(myContestList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getMyCreatedContestList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(myContestList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(topic),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static editTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/editTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(topic),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }
    static delTopic(problem_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/delTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ problem_id: problem_id }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static uploadTopicAttachment(file) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;

        const request = new Request(API.URL + 'jyedu-backend/topic/uploadTopicAttachment?refresh_token=' + refresh_token, {
            method: 'POST',
            body: file,
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw res
            }
        });
    }

    static getContestUserList(contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getContestUserList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: contestId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicListBySet(setId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicListBySet?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ setId: setId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static addContest(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/addContest?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(contest),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editContest(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/editContest?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(contest),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static delContest(contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/delContest?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: contestId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addKnowledge(course) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/addKnowledge?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(course),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editKnowledge(course) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/editKnowledge?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(course),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteKnowledge(kid) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/deleteKnowledge?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ kId: kid }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getAllKnowledgeReplyList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getAllKnowledgeReplyList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addKnowledgeReply(reply) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/addKnowledgeReply?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(reply),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateKnowledgeReply(reply) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/updateKnowledgeReply?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(reply),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteKnowledgeReply(kdId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/deleteKnowledgeReply?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ kdId: kdId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getMsgList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getMsgList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getReplyMsg() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getReplyMsg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static sendMsg(sendMsg) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/sendMsg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(sendMsg),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteMsg(deleteMsg) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/deleteMsg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(deleteMsg),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static checkMsg() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/checkMsg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static replyMsg(replyMsg) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/replyMsg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(replyMsg),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLoginList(loginSearch) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getLoginList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(loginSearch),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    // 获取积分列表
    static getPointsList(loginSearch) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPointsList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(loginSearch),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    // 删除积分
    static deletePoints(loginSearch) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deletePoints?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(loginSearch),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    // 添加积分
    static addPoints(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addPoints?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    // 修改积分
    static updatePoints(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updatePoints?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUserPermissionList(userId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getUserPermissionList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ userId: userId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUserInfo(userId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getUserInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ userId: userId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getCheckInUserList(sign_date, plan_id, contest_id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getCheckInUserList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ sign_date: sign_date, plan_id: plan_id, contest_id: contest_id }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static updateCheckIn(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateCheckIn?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getPersonalCheckInHistory(userIdList, dateRange) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPersonalCheckInHistory?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ userList: userIdList, dateRange: dateRange }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getMySolutionStatus(solutionId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getMySolutionStatus?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ solutionId: solutionId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static removeUserPermission(userId, contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/removeUserPermission?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ userId: userId, contestId: contestId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUserSubmitHistory(userId, submitDate) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getUserSubmitHistory?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ queryUserId: userId, submitDate: submitDate }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getFavoritesList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getFavoritesList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }),
            });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addFavorites(contestId, problemId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addFavorites?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: contestId, problemId: problemId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateFavorites(id, tag) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateFavorites?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ id: id, tag: tag }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteFavorites(id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteFavorites?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ favorite_id: id }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static checkMySubmitStatus() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/checkMySubmitStatus?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicHint(problemId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getTopicHint?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ topicId: problemId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static resetUserPWD(userId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/resetUserPWD?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ userId: userId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static resetUserPWD(userId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/resetUserPWD?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify({ userId: userId }),
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLatestSubmit() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getLatestSubmit?refresh_token=' + refresh_token, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    // id, signDate, signComment, teacher_id
    static updateCheckInRecord(updateCheckInRecord) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateCheckInRecord?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(updateCheckInRecord),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteCheckInRecord(deleteCheckInRecord) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteCheckInRecord?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(deleteCheckInRecord),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getSolutionScreenshotList(shot) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getSolutionScreenshotList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(shot),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createSolutionScreenshot(crteateShot) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/createSolutionScreenshot?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(crteateShot),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteSolutionScreenshot(shotId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/deleteSolutionScreenshot?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ shotId: shotId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getSolutionScreenshotDetail(detail) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getSolutionScreenshotDetail?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(detail),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static uploadMsgAttachment(file) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/uploadMsgAttachment?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: file,
                headers: new Headers({ Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static rejudgeSolution(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/rejudgeSolution?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static lockUserInfoUpdate(status) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/lockUserInfoUpdate?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(status),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static batchImportUser(batchImportUser) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/batchImportUser?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(batchImportUser),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static checkExistsUser(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/checkExistsUser?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTodaySummaryDetail(todaySummaryDetail) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getTodaySummaryDetail?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(todaySummaryDetail),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createProblemShare(createProblemShare) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createProblemShare?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(createProblemShare),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUserContestStatus(userContestStatus) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getUserContestStatus?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(userContestStatus),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getProblemShareList(problemShareList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getProblemShareList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(problemShareList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getJudgerList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getJudgerList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createJudger(createJudger) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/createJudger?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(createJudger),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateJudger(updateJudger) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/updateJudger?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(updateJudger),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getChoiceTopicList(choiceTopicList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getChoiceTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(choiceTopicList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createChoiceTopic(createChoiceTopic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createChoiceTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(createChoiceTopic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static delChoiceTopic(delChoiceTopic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/delChoiceTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(delChoiceTopic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateChoiceTopic(updateChoiceTopic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/updateChoiceTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(updateChoiceTopic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });

    }

    static getContestEditChoiceTopicList(contestId) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestEditChoiceTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: contestId }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestChoiceTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestChoiceTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getMyEvaluationContestList(contest) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getMyEvaluationContestList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(contest),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getEvaluationContestTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getEvaluationContestTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static submitEvaluationSolution(evaluationSolution) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/submitEvaluationSolution?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(evaluationSolution),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getEvaluationSubmitHistory(history) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getEvaluationSubmitHistory?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(history),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getScratchSubmitHistory(history) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getScratchSubmitHistory?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(history),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static judgeScratchSubmit(history) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/judgeScratchSubmit?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(history),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }


    static getCompletionTopicList(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getCompletionTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(topic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createCompletionTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createCompletionTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(topic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateCompletionTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/updateCompletionTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(topic),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static delCompletionTopic(id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/delCompletionTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(id),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestCompletionTopicList(id) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getContestCompletionTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(id),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getClassSignInStatus(signInStatus) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getClassSignInStatus?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(signInStatus),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getChoiceProblemShareList(problemShareList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getChoiceProblemShareList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(problemShareList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getCompletionProblemShareList(problemShareList) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getCompletionProblemShareList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(problemShareList),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteTopicAttachment(url) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/deleteTopicAttachment?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ url: url }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.status;
            } else {
                throw new Error(status);
            }
        });
    }

    static getClassShotList(classShot) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getClassShotList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(classShot),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTermList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getTermList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addClassShotDate(shotDate) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addClassShotDate?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(shotDate),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getShotDateList(shotDate) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getShotDateList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(shotDate),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateClassShotRecord(classShot) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateClassShotRecord?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(classShot),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getEvaluationSummary(summary) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getEvaluationSummary?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(summary),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getActivityList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getActivityList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static customPythonSolution(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/customPythonSolution?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addActivity(addActivity) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/addActivity?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(addActivity),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editActivity(editActivity) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/editActivity?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(editActivity),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteActivity(deleteActivity) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/deleteActivity?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(deleteActivity),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addActivityReg(addActivityReg) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/addActivityReg?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(addActivityReg),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getActivityRegList(activityReg) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getActivityRegList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(activityReg),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTopicEvaluationResult(EvaluationResult) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getTopicEvaluationResult?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(EvaluationResult),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static judgeEvaluation(judgeEvaluation) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/judgeEvaluation?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(judgeEvaluation),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getEvaluationRuntimeInfo(RuntimeInfo) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getEvaluationRuntimeInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(RuntimeInfo),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getProductList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getProductList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    // 前置模式支付url
    static beforeMemberPaymentUrl(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/beforeMemberPaymentUrl?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static memberPaymentUrl(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/memberPaymentUrl?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getProductList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getProductList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addProduct(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addProduct?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateProduct(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateProduct?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static offProduct(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/offProduct?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    // 前置模式 定时请求
    static beforeConfirmPayment(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/beforeConfirmPayment?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    // 前置模式 更新我的权益
    static refreshEquity(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/refreshEquity?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static confirmPayment(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/confirmPayment?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getPaymentList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPaymentList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static payRefund(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/payRefund?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getPrivate() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getPrivate?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addCoupon(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addCoupon?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getCouponUserList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getCouponUserList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static updateCoupon(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateCoupon?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteCoupon(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteCoupon?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getCouponList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getCouponList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getContestInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getTrainNumList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getTrainNumList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static addTrainNum(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addTrainNum?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateTrainNum(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateTrainNum?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteTrainNum(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteTrainNum?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContestResultUserList(cid) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getContestResultUserList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify({ contestId: cid }),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getClassPerformance(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getClassPerformance?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static getSignDateRange(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getSignDateRange?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addClassPerformance(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addClassPerformance?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createBlocklyTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/createBlocklyTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: topic,
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static editBlocklyTopic(topic) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/editBlocklyTopic?refresh_token=' + refresh_token, {
            method: 'POST',
            body: topic,
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        });
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                const error = new Error('创建/修改题目失败');
                error.response = res
                throw error
            }
        });
    }

    static getLoginCodeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getLoginCodeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createLoginCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/createLoginCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editLoginCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/editLoginCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteLoginCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteLoginCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static codeRandomReturn() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/codeRandomReturn?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getBlocklySolution(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getBlocklySolution?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static allowViewingQuestion(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/allowViewingQuestion?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteBlocklySolution(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/deleteBlocklySolution?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static checkTestCodeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/checkTestCodeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static checkTestPersonalCodeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/checkTestPersonalCodeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getImageList() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getImageList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteImageList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/deleteImageList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getOneTopicInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getOneTopicInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static clansApplicationApproval(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/clansApplicationApproval?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static isApplicationApproval(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/isApplicationApproval?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }


    static getClansList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getClansList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteClansList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteClansList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addClans(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addClans?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getUserClansInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getUserClansInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static joinClans(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/joinClans?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getPersonalApplication(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getPersonalApplication?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static myClansPersonal(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/myClansPersonal?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static isPersonalApproval(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/isPersonalApproval?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static exitClans(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/exitClans?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateClansInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateClansInfo?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static createLesson(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/createLesson?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLessonList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getLessonList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addLessonPlan(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/addLessonPlan?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLessonPlanList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getLessonPlanList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getRedisLessonPlanList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getRedisLessonPlanList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteLesson(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/deleteLesson?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateLessonPlan(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/updateLessonPlan?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getLessonStudent(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getLessonStudent?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getDayPlanList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getDayPlanList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static invitationCodeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/invitationCodeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static addInvitationCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/addInvitationCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static editInvitationCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/editInvitationCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteInvitationCode(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/deleteInvitationCode?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }
    static register(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/register?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateTime() {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateTime?refresh_token=' + refresh_token,
            {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getSignInDateList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/contest/getSignInDateList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static randomTopic(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/randomTopic?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static answerSituation(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/answerSituation?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getOneDayTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getOneDayTopicList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static drawFlowChart(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/drawFlowChart?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getFlowChartPersonal(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/topic/getFlowChartPersonal?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getIsTopicPower(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/getIsTopicPower?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContactUs(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/contactUs?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getContactList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/getContactList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static deleteContact(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/deleteContact?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateContact(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/message/updateContact?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static updateStudentsToInviteeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/updateStudentsToInviteeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getInviteeList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getInviteeList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getBriefClassUserListByClassId(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getBriefClassUserListByClassId?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static getWebsocketOnlineUserList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/user/getWebsocketOnlineUserList?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

    static exportAllSubmitHistoryExcel(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        const request = new Request(API.URL + 'jyedu-backend/solution/exportAllSubmitHistoryExcel?refresh_token=' + refresh_token,
            {
                method: 'POST',
                body: JSON.stringify(options),
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            },
        );
        return fetch(request).then(res => {
            const status = res.status;
            if (status >= 200 && status < 300) {
                return res.json();
            } else {
                throw new Error(status);
            }
        });
    }

}

export default API;