/**
 * redux 管理入口
 */
import { combineReducers } from "redux";
import {
    SEARCH_TOPIC,
    SEARCH_USERLIST,
    SEARCH_LOGINLIST,
    SEARCH_RANK,
    SEARCH_SUBMIT,
    CONTESTS,
    SEARCH_INPUT,
    ONLINEHELP,
    SEARCH_MYINPUT,
    FAVORITES,
    SEARCH_SUBMITDETAIL,
    SIDERKEY,
    KNOWLEDGESHAREKEY,
    SEARCH_STUDENT,
    MYCONTESTS,
    MONACODE,
    CONTESTRANK,
    CONTESTCODE,
    MESSAGE,
    TOPICS,
    RESET,
    CHOICETOPIC,
    SUMMARYTODAY,
    EVALUATION,
    SCRATCHSUBMIT,
    EVALUATIONMGMT,
    EVALUATIONCENTER,
    WORKPLACE,
    JOBMGMT,
    QUESTIONBANK,
    STUDENTMGMT,
    CLASSSIGNIN,
    CHECKINHISTORY,
    EVALUATIONMGMTLIST,
    SCRATCHSUBMITLIST,
    CLASSSHOT,
    PORTRAIT,
    PLATFORM,
    SNAPSHOT,
    SNAPSHOTDETAIL,
    SCHOOLMGMT,
    TOPICMGMT,
    EQUITYMGMT,
    CLASSPERFORMANCE,
    MYCOURSE,
    MYCOURSEMGMT,
    EXPORTTOPICS,
    TOPICADD,
    CHECKTESTCODE,
    TOPICEDITFLOW,
    FLOWCHART,
    TOPICDETAILFLOW,
    SUBMITTOPICDETAILFLOW,
    COMPLETIONTOPICLIST,
    BLOCKLYTOPICLIST,
    BROADCAST,
    TEACHERBROADCAST
} from "../actions/type"

const initialTopicsMgmt = {
    topicsId: '',
    searchInput: '',
    problemSetName: '',
    page: 1,
    pageSize: 10,
    checkInfo: [],
}
const initialUserList = {
    classId: '',
    defunct: '',
    queryContent: '',
    page: 1,
    pageSize: 10
}
const initialLoginList = {
    classId: '',
    loginStatus: '',
    searchInput: '',
    page: 1,
    pageSize: 10
}
const initialRank = {
    className: '',
    rankDatetime: '',
    page: 1,
    pageSize: 50,
    rankList: [],
    total: null
}
const initialContest = sessionStorage.getItem('contestListChange') ? JSON.parse(sessionStorage.getItem('contestListChange')) : {
    contestStatus: '',
    page: 1,
    pageSize: 10,
    cid: '',
    practice: '',
    langmask: '',
    problem_id: '',
    contestList: [],
    total: null,
}
const initialInput = {
    searchInput: '',
    page: 1,
    pageSize: 10
}
const initialTopicEdit = {
    title: '',
    problemset: '',
    time_limit: '',
    memory_limit: '',
    defunct: '',
    score: '',
}
const initialTopicBraft = {
    editorState: ''
}
const initialTopicTestData = {
    testData: []
}
const initialSiderKey = sessionStorage.getItem('siderMenuKey') ? JSON.parse(sessionStorage.getItem('siderMenuKey')) : { siderMenuKey: '1' }
const initialKnowledgeShareKey = { key: '1' }
const initialStudentList = {
    classId: '',
    defunct: '',
    queryContent: '',
    page: 1,
    pageSize: 10
}
const initialSubmitHistory = {
    solutionId: '',
    topicId: '',
    userId: '',
    className: '',
    submitDatetime: '',
    page: 1,
    pageSize: 10,
}
const initialSubmitDetailList = {
    userId: '', submitDatetime: '', page: 1, pageSize: 10,
}

const initialMyContestList = sessionStorage.getItem('myContestListChange') ? JSON.parse(sessionStorage.getItem('myContestListChange')) : {
    contestStatus: '',
    page: 1,
    pageSize: 10,
    cid: '',
    practice: '',
    langmask: '',
    problem_id: '',
    contestList: [],
    total: null,
}
const initialMyCourseList = sessionStorage.getItem('myCourseListChange') ? JSON.parse(sessionStorage.getItem('myCourseListChange')) :
    {
        contestStatus: '',
        page: 1,
        pageSize: 10,
        cid: '',
        practice: '',
        langmask: '',
        problem_id: '',
        courseList: [],
        total: null,
        isPlanInfo: null,
    }

const initialInputMyContest = {
    searchInput: '',
    page: 1,
    pageSize: 10,
    myContestList: [],
    practice: '',
    total: null,
}

const initialInputMyCourse = {
    searchInput: '',
    page: 1,
    pageSize: 10,
    myContestList: [],
    langmask: '',
    total: null,
}

const initialMonacoEditCode = new Map()
const initialOnlineHelp = { inputSearch: '', page: 1, pageSize: 5, }
const initialFavoritesList = { searchInput: '', page: 1, pageSize: 10 }
const initialContestRank = { plan_id: null, classId: '', contest_id: null, page: 1, pageSize: 10 }
const initialContestCode = { page: 1, pageSize: 10, language: '', classId: '' }
const initialMessage = { searchInput: '', dateSearch: null, page: 1, pageSize: 10 }
const initialTopicsPage = new Map()
const initSummaryToday = {
    classId: null,
    summaryDatetime: [],
    page: 1,
    pageSize: 10,
}

const initialChoiceMgmt = {
    topicsId: '',
    searchInput: '',
    problemSetName: '',
    page: 1,
    pageSize: 10,
    checkInfo: [],
}

const iniEvaluation = new Map();

const iniEvaluationMgmt = new Map();

const iniScratchSubmit = {
    datetime: [],
    classId: '',
    studentName: ''
}

const iniEvaluationCenter = {
    contestStatus: '',
    searchInput: '',
    choiceList: [],
    page: 1,
    pageSize: 10,
    total: null
}

const iniWorkplace = {
    key: '1'
}

const iniJobmgmt = {
    key: '1'
}

const iniEquityMgmt = {
    key: '1'
}

const iniQuestionBank = {
    key: '1'
}

const iniStudentmgmt = {
    key: '1'
}

const iniClassSignIn =
{
    logintimeRange: [],
    classId: null
}

const iniCheckInList = {
    userList: [],
    signStatus: '',
    dateSearch: [],
}

const iniEvaluationMgmtList = {
    searchInput: '',
    page: 1,
    pageSize: 10,
    total: null,
    evaluationList: []
}
const iniScratchSubmitList = {
    searchInput: '',
    page: 1,
    pageSize: 10,
    total: null,
    scratchSubmitList: []
}

const iniClassShot = {
    classId: null,
    yearId: null,
    page: 1,
    pageSize: 10,
}

const iniPortrait = {
    portraitList: [],
    page: 1,
    pageSize: 10,
    total: null
}

const iniPlatformMgmt = {
    platfromKey: '1',
    userMgmtKey: '1',
    knoledgeKey: '1',
}

const iniSnapshot = {
    page: 1,
    pageSize: 10,
    snapshotList: [],
    total: null,
}

const iniSnapshotDetail = new Map();

const iniSchoolMgmt = {
    page: 1,
    pageSize: 10,
    total: null,
    schoolList: [],
}

const iniTopicMgmt = {
    page: 1,
    pageSize: 10,
    total: null,
    topicSet: [],
}

const iniClassPerformance = {
    page: 1,
    pageSize: 10,
    className: '',
    checkInDate: new Date(),
    performanceList: []
}

const iniExportTopics = {
    exportTopicList: [],
    pdfTitle: null,
    page: 1,
    pageSize: 10,
}

const iniTopicAdd = {
    description: null,
    hint: null,
    title: '',
    topicSetName: null,
    score: 30,
    time_limit: 1,
    memory_limit: 32,
    defunct: 'N',
    testData: [],
    tagList: [],
    problem_keywords_list: [],
    topicAddFlow: null,
}

const iniCheckTestCode = {
    language: '1',
    page: 1,
    pageSize: 20,
    className: '',
    studentName: ''
}

const iniTopicEditFlow = new Map();
const iniFlowChart = {
    chartNode: null
}

const iniTopicDetailFlow = new Map();
const iniSubmitTopicDetail = new Map();

const iniCompletionTopicList = {
    checkInfo: []
}

const iniBlocklyTopicList = {
    checkInfo: []
}

const iniBroadcast = {
    selectedContestId: '',
    isStudentBroadcastOpen: false,
    isStudentReceiveBroadcast: false
}

const iniTeacherBroadcast = {
    selectedContestId: '',
    isTeacherBroadcast: false,
    isTeacherBroadcastModalShow: false,
    isMonitorShow: false
}



/* 不同数据结构的单个state */

function siderKeyChange(state = initialSiderKey, action) {
    const { data, type } = action
    switch (type) {
        case SIDERKEY:
            sessionStorage.setItem('siderMenuKey', JSON.stringify({ ...state, ...data }))
            return { ...state, ...data };
        case RESET:
            sessionStorage.setItem('siderMenuKey', JSON.stringify(initialSiderKey))
            return initialSiderKey;
            break;
        default: return state;
    }
}

function knowledgeShareKeyChange(state = initialKnowledgeShareKey, action) {
    const { data, type } = action
    switch (type) {
        case KNOWLEDGESHAREKEY:
            return { ...state, ...data };
        case RESET:
            return initialKnowledgeShareKey;
            break;
        default: return state;
    }
}

function topicsFilterChange(state = initialTopicsMgmt, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_TOPIC:
            return { ...state, ...data };
        case RESET:
            return initialTopicsMgmt;
            break;
        default: return state;
    }
}

function userListFilter(state = initialUserList, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_USERLIST:
            return { ...state, ...data };
        case RESET:
            return initialUserList;
            break;
        default: return state;
    }
}

function studentListFilter(state = initialStudentList, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_STUDENT:
            return { ...state, ...data };
        case RESET:
            return initialStudentList;
            break;
        default: return state;
    }
}

function loginListChange(state = initialLoginList, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_LOGINLIST:
            return { ...state, ...data };
        case RESET:
            return initialLoginList;
            break;
        default: return state;
    }
}

function rankListChange(state = initialRank, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_RANK:
            return { ...state, ...data };
        case RESET:
            return initialRank;
            break;
        default: return state;
    }
}

function submitHistoryChange(state = initialSubmitHistory, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_SUBMIT:
            return { ...state, ...data };
        case RESET:
            return initialSubmitHistory;
            break;
        default: return state;
    }
}

function submitDetailListChange(state = initialSubmitDetailList, action) {
    const { data, type } = action
    switch (type) {
        case SEARCH_SUBMITDETAIL:
            return { ...state, ...data };
        case RESET:
            return initialSubmitDetailList;
            break;
        default: return state;
    }
}

function contestListChange(state = initialContest, action) {
    const { data, type } = action
    switch (type) {
        case CONTESTS:
            sessionStorage.setItem('contestListChange', JSON.stringify({ ...state, ...data }))
            return { ...state, ...data };
        case RESET:
            sessionStorage.setItem('contestListChange', JSON.stringify(initialContest))
            return initialContest;
            break;
        default: return state;
    }
}

function myContestListChange(state = initialMyContestList, action) {
    const { data, type } = action
    switch (type) {
        case MYCONTESTS:
            sessionStorage.setItem('myContestListChange', JSON.stringify({ ...state, ...data }))
            return { ...state, ...data };
        case RESET:
            sessionStorage.setItem('myContestListChange', JSON.stringify(initialMyContestList))
            return initialMyContestList;
            break;
        default: return state;
    }
}

function myCourseListChange(state = initialMyCourseList, action) {
    const { data, type } = action
    switch (type) {
        case MYCOURSE:
            sessionStorage.setItem('myCourseListChange', JSON.stringify({ ...state, ...data }))
            return { ...state, ...data };
        case RESET:
            sessionStorage.setItem('myCourseListChange', JSON.stringify(initialMyCourseList))
            return initialMyCourseList;
            break;
        default: return state;
    }
}

function inputSearchChange(state = initialInput, action) {
    const { type, data } = action
    switch (type) {
        case SEARCH_INPUT:
            return { ...state, ...data };
        case RESET:
            return initialInput;
            break;
        default: return state;
    }
}

function inputMyContestChange(state = initialInputMyContest, action) {
    const { type, data } = action
    switch (type) {
        case SEARCH_MYINPUT:
            return { ...state, ...data };
        case RESET:
            return initialInputMyContest;
            break;
        default: return state;
    }
}

function inputMyCourseChange(state = initialInputMyCourse, action) {
    const { type, data } = action
    switch (type) {
        case MYCOURSEMGMT:
            return { ...state, ...data };
        case RESET:
            return initialInputMyCourse;
            break;
        default: return state;
    }
}

function monacoEditCode(state = initialMonacoEditCode, action) {
    const { type, data } = action
    switch (type) {
        case MONACODE:
            return state.set(data.problemId, data.code);
        case RESET:
            state.clear()
            return initialMonacoEditCode;
            break;
        default: return state;
    }
}

function onlineHelpChange(state = initialOnlineHelp, action) {
    const { type, data } = action;
    switch (type) {
        case ONLINEHELP:
            return { ...state, ...data };
        case RESET:
            return initialOnlineHelp;
            break;
        default: return state
    }
}

function favoritesListChange(state = initialFavoritesList, action) {
    const { type, data } = action;
    switch (type) {
        case FAVORITES:
            return { ...state, ...data };
        case RESET:
            return initialFavoritesList;
            break;
        default: return state;
    }
}

function contestRank(state = initialContestRank, action) {
    const { type, data } = action;
    switch (type) {
        case CONTESTRANK:
            return { ...state, ...data };
        case RESET:
            return initialContestRank;
            break;
        default: return state;
    }
}
function contestCode(state = initialContestCode, action) {
    const { type, data } = action;
    switch (type) {
        case CONTESTCODE:
            return { ...state, ...data };
        case RESET:
            return initialContestCode;
            break;
        default: return state;
    }
}

function messageChange(state = initialMessage, action) {
    const { type, data } = action;
    switch (type) {
        case MESSAGE:
            return { ...state, ...data };
        case RESET:
            return initialMessage;
            break;
        default: return state;
    }
}

function topicsPageChange(state = initialTopicsPage, action) {
    const { type, data } = action;
    switch (type) {
        case TOPICS:
            if (data.plan_id != '') {
                let id = data.contest_id + data.plan_id
                return state.set(id, data);
            } else {
                return state.set(data.contest_id, data);
            }
        case RESET:
            state.clear()
            return initialTopicsPage;
            break;
        default: return state;
    }
}

function summaryTodayChange(state = initSummaryToday, action) {
    const { type, data } = action;
    switch (type) {
        case SUMMARYTODAY:
            return { ...state, ...data };
        case RESET:
            return initSummaryToday;
            break;
        default: return state;
    }
}

function choiceTopicChange(state = initialChoiceMgmt, action) {
    const { type, data } = action;
    switch (type) {
        case CHOICETOPIC:
            return { ...state, ...data };
        case RESET:
            return initialChoiceMgmt;
            break;
        default: return state;
    }
}

function evaluationChange(state = iniEvaluation, action) {
    const { type, data } = action;
    switch (type) {
        case EVALUATION:
            return state.set(data.contestId, data.value)
        case RESET:
            state.clear()
            return iniEvaluation;
            break;
        default: return state;
    }
}
function scratchSubmitChange(state = iniScratchSubmit, action) {
    const { type, data } = action;
    switch (type) {
        case SCRATCHSUBMIT:
            return { ...state, ...data };
        case RESET:
            return iniScratchSubmit;
            break;
        default: return state;
    }
}

function evaluationMgmtChange(state = iniEvaluationMgmt, action) {
    const { type, data } = action;
    switch (type) {
        case EVALUATIONMGMT:
            return state.set(data.contestId, data.value);
        case RESET:
            state.clear()
            return iniEvaluationMgmt;
            break;
        default: return state;
    }
}

function evaluationCenterChange(state = iniEvaluationCenter, action) {
    const { type, data } = action;
    switch (type) {
        case EVALUATIONCENTER:
            return { ...state, ...data };
        case RESET:
            return iniEvaluationCenter;
            break;
        default: return state;
    }
}

function workplaceChange(state = iniWorkplace, action) {
    const { type, data } = action;
    switch (type) {
        case WORKPLACE:
            return { ...state, ...data };
        case RESET:
            return iniWorkplace;
            break;
        default: return state;
    }
}

function jobmgmtChange(state = iniJobmgmt, action) {
    const { type, data } = action;
    switch (type) {
        case JOBMGMT:
            return { ...state, ...data };
        case RESET:
            return iniWorkplace;
            break;
        default: return state;
    }
}

function questionBankChange(state = iniQuestionBank, action) {
    const { type, data } = action;
    switch (type) {
        case QUESTIONBANK:
            return { ...state, ...data };
        case RESET:
            return iniWorkplace;
            break;
        default: return state;
    }
}

function studentMgmtChange(state = iniStudentmgmt, action) {
    const { type, data } = action;
    switch (type) {
        case STUDENTMGMT:
            return { ...state, ...data };
        case RESET:
            return iniWorkplace;
            break;
        default: return state;
    }
}

function classSignInChange(state = iniClassSignIn, action) {
    const { type, data } = action
    switch (type) {
        case CLASSSIGNIN:
            return { ...state, ...data };
        case RESET:
            return iniClassSignIn;
            break;
        default: return state;
    }
}

function checkInListChange(state = iniCheckInList, action) {
    const { type, data } = action
    switch (type) {
        case CHECKINHISTORY:
            return { ...state, ...data };
        case RESET:
            return iniCheckInList;
            break;
        default: return state;
    }
}

function evaluationMgmtListChange(state = iniEvaluationMgmtList, action) {
    const { type, data } = action
    switch (type) {
        case EVALUATIONMGMTLIST:
            return { ...state, ...data };
        case RESET:
            return iniEvaluationMgmtList;
        default: return state;
    }
}

function scratchSubmitListChange(state = iniScratchSubmitList, action) {
    const { type, data } = action
    switch (type) {
        case SCRATCHSUBMITLIST:
            return { ...state, ...data };
        case RESET:
            return iniScratchSubmitList;
        default: return state;
    }
}

function classShotChange(state = iniClassShot, action) {
    const { type, data } = action
    switch (type) {
        case CLASSSHOT:
            return { ...state, ...data };
        case RESET:
            return iniClassShot;
        default: return state;
    }
}

function portraitChange(state = iniPortrait, action) {
    const { type, data } = action
    switch (type) {
        case PORTRAIT:
            return { ...state, ...data };
        case RESET:
            return iniPortrait;
        default: return state;
    }
}

function platformMgmtChange(state = iniPlatformMgmt, action) {
    const { type, data } = action
    switch (type) {
        case PLATFORM:
            return { ...state, ...data };
        case RESET:
            return iniPlatformMgmt;
        default: return state;
    }
}

function equityMgmtChange(state = iniEquityMgmt, action) {
    const { type, data } = action
    switch (type) {
        case EQUITYMGMT:
            return { ...state, ...data };
        case RESET:
            return iniEquityMgmt;
        default: return state;
    }
}

function snapshotChange(state = iniSnapshot, action) {
    const { type, data } = action
    switch (type) {
        case SNAPSHOT:
            return { ...state, ...data };
        case RESET:
            return iniSnapshot;
        default: return state;
    }
}

function snapshotDetailChange(state = iniSnapshotDetail, action) {
    const { type, data } = action
    switch (type) {
        case SNAPSHOTDETAIL:
            return state.set(data.shotId, data);
        case RESET:
            return iniSnapshotDetail;
        default: return state;
    }
}

function schoolMgmtChange(state = iniSchoolMgmt, action) {
    const { type, data } = action
    switch (type) {
        case SCHOOLMGMT:
            return { ...state, ...data };
        case RESET:
            return iniSchoolMgmt;
        default: return state;
    }
}

function topicMgmtChange(state = iniTopicMgmt, action) {
    const { type, data } = action
    switch (type) {
        case TOPICMGMT:
            return { ...state, ...data };
        case RESET:
            return iniTopicMgmt;
        default: return state;
    }
}

function classPerformanceChange(state = iniClassPerformance, action) {
    const { type, data } = action
    switch (type) {
        case CLASSPERFORMANCE:
            return { ...state, ...data };
        case RESET:
            return iniClassPerformance;
        default: return state;
    }
}

function exportTopicsChange(state = iniExportTopics, action) {
    const { type, data } = action
    switch (type) {
        case EXPORTTOPICS:
            return { ...state, ...data };
        case RESET:
            return iniExportTopics;
        default: return state;
    }
}

function topicAddChange(state = iniTopicAdd, action) {
    const { type, data } = action
    switch (type) {
        case TOPICADD:
            return { ...state, ...data };
        case RESET:
            return iniTopicAdd;
        default: return state;
    }
}

function checkTestCodeChange(state = iniCheckTestCode, action) {
    const { type, data } = action
    switch (type) {
        case CHECKTESTCODE:
            return { ...state, ...data };
        case RESET:
            return iniCheckTestCode;
        default: return state;
    }
}

function topicEditFlowChange(state = iniTopicEditFlow, action) {
    const { type, data } = action
    switch (type) {
        case TOPICEDITFLOW:
            if (data.problem_id != '') {
                const { problem_id, ...nodeData } = data;
                let id = data.problem_id
                return state.set(id, nodeData);
            }
        case RESET:
            state.clear(iniTopicEditFlow,)
            return iniTopicEditFlow;
        default: return state;
    }
}

function flowChartChange(state = iniFlowChart, action) {
    const { type, data } = action
    switch (type) {
        case FLOWCHART:
            return { ...state, ...data };
        case RESET:
            return iniFlowChart;
        default: return state;
    }
}

function topicDetailFlowChange(state = iniTopicDetailFlow, action) {
    const { type, data } = action
    switch (type) {
        case TOPICDETAILFLOW:
            if (data.problem_id != '' && data.contest_id != '' && data.plan_id && data.userId) {
                const { problem_id, plan_id, contest_id, userId, nodes } = data;
                let id = plan_id.toString() + contest_id.toString() + problem_id.toString() + userId;
                return state.set(id, { nodes: nodes });
            } else {
                const { problem_id, contest_id, nodes } = data;
                let id = contest_id.toString() + problem_id.toString() + userId
                return state.set(id, { nodes: nodes });//value一定要符合流程图数据格式
            }
        case RESET:
            return iniTopicDetailFlow;
        default: return state;
    }
}

function SubmitHistoryTopicDetailChange(state = iniSubmitTopicDetail, action) {
    const { type, data } = action
    switch (type) {
        case SUBMITTOPICDETAILFLOW:
            if (data.problem_id != '' && data.contest_id != '' && data.plan_id && data.userId) {
                const { problem_id, plan_id, contest_id, userId, nodes } = data;
                let id = plan_id.toString() + contest_id.toString() + problem_id.toString() + userId;
                return state.set(id, { nodes: nodes });
            } else {
                const { problem_id, contest_id, userId, nodes } = data;
                let id = contest_id.toString() + problem_id.toString() + userId
                return state.set(id, { nodes: nodes });//value一定要符合流程图数据格式
            }
        case RESET:
            return iniSubmitTopicDetail;
        default: return state;
    }
}

function completionTopicChange(state = iniCompletionTopicList, action) {
    const { type, data } = action
    switch (type) {
        case COMPLETIONTOPICLIST:
            return { ...state, ...data };
        case RESET:
            return iniCompletionTopicList;
        default: return state;
    }
}

function blocklyTopicChange(state = iniBlocklyTopicList, action) {
    const { type, data } = action
    switch (type) {
        case BLOCKLYTOPICLIST:
            return { ...state, ...data };
        case RESET:
            return iniBlocklyTopicList;
        default: return state;
    }
}

function broadcastChange(state = iniBroadcast, action) {
    const { type, data } = action
    switch (type) {
        case BROADCAST:
            return { ...state, ...data };
        case RESET:
            return iniBroadcast;
        default: return state;
    }
}

function teacherBroadcastChange(state = iniTeacherBroadcast, action) {
    const { type, data } = action
    switch (type) {
        case TEACHERBROADCAST:
            return { ...state, ...data };
        case RESET:
            return iniTeacherBroadcast;
        default: return state;
    }
}

// 汇总成一个大的state,供全局使用
const reducers = combineReducers({
    checkTestCodeChange,
    inputMyCourseChange,
    topicsFilterChange,
    userListFilter,
    studentListFilter,
    loginListChange,
    rankListChange,
    submitHistoryChange,
    contestListChange,
    myContestListChange,
    inputSearchChange,
    onlineHelpChange,
    inputMyContestChange,
    favoritesListChange,
    submitDetailListChange,
    siderKeyChange,
    knowledgeShareKeyChange,
    monacoEditCode,
    contestCode,
    contestRank,
    messageChange,
    topicsPageChange,
    summaryTodayChange,
    choiceTopicChange,
    evaluationChange,
    scratchSubmitChange,
    evaluationMgmtChange,
    evaluationCenterChange,
    workplaceChange,
    jobmgmtChange,
    questionBankChange,
    studentMgmtChange,
    classSignInChange,
    checkInListChange,
    evaluationMgmtListChange,
    scratchSubmitListChange,
    classShotChange,
    portraitChange,
    platformMgmtChange,
    snapshotChange,
    snapshotDetailChange,
    schoolMgmtChange,
    topicMgmtChange,
    equityMgmtChange,
    classPerformanceChange,
    myCourseListChange,
    exportTopicsChange,
    topicAddChange,
    topicEditFlowChange,
    flowChartChange,
    topicDetailFlowChange,
    SubmitHistoryTopicDetailChange,
    completionTopicChange,
    blocklyTopicChange,
    broadcastChange,
    teacherBroadcastChange
});
export default reducers;